import { httpsCallable } from "firebase/functions";
import { OrderData, StripeResponse } from "../../../types";
import { auth, functions } from "../../../config/Firebase";
import {
  generateErrorFirebaseAndAxiosErrors,
  hostUrl,
} from "../../../api/helper";

export const StripeCheckout = async (order: OrderData) => {
  try {
    const StripeRequest = httpsCallable(functions, "StripeRequest");
    const resData = await StripeRequest({
      menuData: order.menu,
      hostUrl: hostUrl,
      orderId: order.id,
      uId: auth.currentUser?.uid,
      uMail: auth.currentUser?.email,
    });
    const response = resData.data as StripeResponse;
    window.location.href = String(response.url);
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors(
      "決済に失敗しました。申し訳ございませんが、時間を空けて再度お試しください。",
      e
    );
  }
};
