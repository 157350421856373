import { httpsCallable } from "firebase/functions";
import { OrderData, PayPayCreateQrResponse } from "../../../types";
import { functions } from "../../../config/Firebase";
import {
  generateErrorFirebaseAndAxiosErrors,
  hostUrl,
} from "../../../api/helper";

export const PayPayCreateQR = async (order: OrderData) => {
  try {
    const PayPayRequest = httpsCallable(functions, "PayPayRequest");
    const resData = await PayPayRequest({
      orderId: order.id,
      hostUrl: hostUrl,
      amount: order.totalPrice,
      orderDescription: order.menu.map((item) => item.title).join(","),
    });
    const response = resData.data as PayPayCreateQrResponse;
    window.location.href = String(response.data.url);
  } catch (e) {
    console.error(e);
    throw generateErrorFirebaseAndAxiosErrors(
      "決済に失敗しました。申し訳ございませんが、時間を空けて再度お試しください。",
      e
    );
  }
};
