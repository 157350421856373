import { useEffect, useRef, useState } from "react";
import { DocumentSnapshot } from "firebase/firestore";
import { SearchCollectionAndSetData } from "./api";
import { Card, HistoryTabs } from "./components";
import { OrderData } from "../../types";
import { RedirectToErrorPage } from "../../api/helper";
import { LoadingAnimation } from "../../components/LoadingAnimation";
import { Skeleton } from "./components";

interface lastDocType {
  lastDoc: DocumentSnapshot | null;
  lastFetchData: OrderData[];
}
export const History = () => {
  const [allOrderData, setAllOrderData] = useState<OrderData[]>([]);
  const lastDoc = useRef<lastDocType>({} as lastDocType);
  const isScrollValidateEnable = useRef<boolean>(true);
  const [isGetHistoryData, setIsGetHistoryData] = useState<boolean>(false);
  const [filterStatusListNumber, setFilterStatusListNumber] = useState(0);
  const [isTabChanged, setIsTabChanged] = useState(false);
  const [isAsyncFetch, setIsAsyncFetch] = useState(false);
  const filterStatusList = ["all", "complete", "ordered", "not_payed"];

  useEffect(() => {
    (async () => {
      try {
        setAllOrderData([]);
        setIsAsyncFetch(true);
        setIsTabChanged(false);
        lastDoc.current.lastDoc = null;
        lastDoc.current = await SearchCollectionAndSetData(
          filterStatusList[filterStatusListNumber],
          setAllOrderData,
          lastDoc.current.lastDoc
        );
        setIsGetHistoryData(true);
      } catch (e) {
        RedirectToErrorPage(e);
      } finally {
        setIsTabChanged(true);
        setIsAsyncFetch(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterStatusListNumber]);

  const handleScroll = async () => {
    const scrollTop = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
    const scrollHeight = Math.max(
      document.documentElement.scrollHeight,
      document.body.scrollHeight
    );
    const clientHeight = document.documentElement.clientHeight;
    if (lastDoc.current.lastFetchData.length === 0) return;
    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      isScrollValidateEnable.current
    ) {
      isScrollValidateEnable.current = false;
      setIsAsyncFetch(true);
      lastDoc.current = await SearchCollectionAndSetData(
        filterStatusList[filterStatusListNumber],
        setAllOrderData,
        lastDoc.current.lastDoc
      );
      isScrollValidateEnable.current = true;
      setIsAsyncFetch(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrderData]);
  return (
    <div>
      {isGetHistoryData ? (
        <div className="mx-auto max-w-3xl rounded-lg bg-white p-[2%] pb-12">
          <h1 className="japanese_L my-5 text-center text-2xl font-bold text-runticketGrayText">
            注文履歴
          </h1>
          <HistoryTabs
            value={filterStatusListNumber}
            setValue={setFilterStatusListNumber}
          />
          {allOrderData.length === 0 && isTabChanged && (
            <div className="japanese_L m-auto mt-10 h-screen text-center text-[1.5rem] text-black opacity-65">
              該当するものがありません
            </div>
          )}
          {allOrderData.length !== 0 && !isAsyncFetch ? (
            <Card
              orderData={allOrderData}
              filterStatusList={filterStatusList}
              filterStatusListNumber={filterStatusListNumber}
            />
          ) : (
            <Skeleton maxItem={10} />
          )}
        </div>
      ) : (
        <LoadingAnimation type="jelly" />
      )}
    </div>
  );
};
