import { Button } from "@mui/material";

interface IllegalEmailAddressProps {
  email: string;
  onClick: () => void;
}

export const IllegalEmailAddress = (props: IllegalEmailAddressProps) => {
  return (
    <div className="mx-auto my-4 w-4/5 text-left">
      <h2 className="text-[1.2rem]">
        本サービスは個人用アカウントでのご利用を許可しておりません。
        <br />
        申し訳ございませんが、組織内アカウント(@oit.ac.jp)で
        ログインし直してください。
      </h2>
      <div className="my-[10%] text-center">
        <Button
          className="bg-runticketBlue"
          onClick={props.onClick}
          variant="contained"
        >
          再ログイン
        </Button>
      </div>
      <p>
        ※何度もこの画面が表示される場合はご利用ブラウザのログイン情報を確認してください。
      </p>
    </div>
  );
};
