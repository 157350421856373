import { deleteDoc, doc } from "firebase/firestore";
import { generateErrorFirebaseAndAxiosErrors } from "../../../api/helper";
import { db } from "../../../config/Firebase";

export const DeleteOrderDocument = async (collectionId: string) => {
  try {
    await deleteDoc(doc(db, "order", collectionId));
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors(
      "注文情報の削除に失敗しました。",
      e
    );
  }
};
