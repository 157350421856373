import {
  DocumentData,
  DocumentSnapshot,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { OrderData } from "../../../types";
import { auth, db } from "../../../config/Firebase";
import { generateErrorFirebaseAndAxiosErrors } from "../../../api/helper";

export const SearchCollectionAndSetData = async (
  isStatus: string,
  setData: React.Dispatch<React.SetStateAction<OrderData[]>>,
  lastDoc: DocumentSnapshot<DocumentData> | null
) => {
  const baseQuery = query(
    collection(db, "order"),
    where("user.uid", "==", auth.currentUser?.uid),
    orderBy("date", "desc"),
    limit(10)
  );

  const q = query(
    baseQuery,
    ...(isStatus !== "all" ? [where("isStatus", "==", isStatus)] : []),
    ...(lastDoc ? [startAfter(lastDoc)] : [])
  );
  try {
    const querySnapshot = await getDocs(q);
    setData(
      (prev) =>
        [...prev, ...querySnapshot.docs.map((doc) => doc.data())] as OrderData[]
    );
    return {
      lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1] || null,
      lastFetchData: querySnapshot.docs.map((doc) => doc.data()) as OrderData[],
    };
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors(
      "注文情報の取得に失敗しました。",
      e
    );
  }
};
