import { useEffect, useState } from "react";
import { OrderData, OrderListTypes } from "../types";
import { Link, useParams, useNavigate } from "react-router-dom";
import { convertToTitleCountFormat, RedirectToErrorPage } from "../api/helper";
import { onSnapshot, doc } from "firebase/firestore";
import { QRCodeSVG } from "qrcode.react";
import { Button, Divider } from "@mui/material";
import { LoadingAnimation } from "../components/LoadingAnimation";
import Slide from "../components/Slide";
import { auth } from "../config/Firebase";
import { db } from "../config/Firebase";
import { Orbit } from "@uiball/loaders";
import {
  DeleteOrderDocument,
  FetchOneOrderDocument,
} from "../features/order/api";
import { useSnackbar } from "notistack";

let isChecked = false;
export const OrderCompleted = () => {
  const [orderData, setOrderData] = useState<OrderData>();
  const [orderList, setOrderList] = useState<OrderListTypes[]>();
  const [isGetOrderData, setIsGetOrderData] = useState<boolean>(false);
  const [isChangeStatus, setIsChangeStatus] = useState<boolean>(false);
  const [isPramsIdError, setIsPramsIdError] = useState<boolean>(false);
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (params.id) {
        try {
          const orderData = await FetchOneOrderDocument(params.id);
          setOrderData(orderData);
          setIsGetOrderData(true);
          setOrderList(convertToTitleCountFormat(orderData.menu));
        } catch (e) {
          setIsPramsIdError(true);
          console.error(e);
        }
      }
    })();
    onSnapshot(doc(db, "order", params.id || ""), (doc) => {
      if (isChecked) {
        doc.data()?.isStatus === "complete" &&
          setOrderData(doc.data() as OrderData);
        setIsChangeStatus(true);
      }
      isChecked = true;
    });
  }, [params.id]);

  useEffect(() => {
    if (params.status === "success" && orderData?.isStatus === "ordered") {
      enqueueSnackbar(
        <div className="japanese_R mx-auto text-white">決済が完了しました</div>,
        {
          variant: "success",
          autoHideDuration: 2000,
        }
      );
    }

    if (params.status === "failed" && orderData?.isStatus === "not_payed") {
      enqueueSnackbar(
        <div className="japanese_R mx-auto text-white">
          決済情報が見つかりません
        </div>,
        {
          variant: "error",
          autoHideDuration: 2000,
        }
      );
      console.log(orderData?.isStatus);
    }
  }, [enqueueSnackbar, orderData?.isStatus, params.status]);

  useEffect(() => {
    if (orderData?.isStatus === "complete" && isChangeStatus) {
      enqueueSnackbar(
        <div className="japanese_R mx-auto text-white">注文完了</div>,
        {
          variant: "success",
          autoHideDuration: 2000,
        }
      );
    }
  }, [enqueueSnackbar, isChangeStatus, orderData?.isStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mx-2 mb-12 mt-2">
      {isGetOrderData || isPramsIdError ? (
        <div className="mx-auto max-w-3xl rounded-lg bg-white p-[2%]">
          {auth.currentUser?.uid === orderData?.user.uid ? (
            <>
              {(orderData?.isStatus === "ordered" ||
                orderData?.isStatus === "cooked") && (
                <div>
                  <h2 className="my-8 text-center font-sans text-3xl text-black">
                    食券発行用QR
                  </h2>
                  <div className="relative size-full py-20">
                    <QRCodeSVG
                      className="absolute right-1/2 top-0 translate-x-1/2"
                      value={orderData?.id}
                      size={150}
                    />
                  </div>
                  <p className=" japanese_R mt-1 text-center text-lg text-black">
                    {`注文ID: ${orderData?.id}`}
                  </p>
                </div>
              )}
              {orderData?.isStatus === "complete" && (
                <p className="my-2 text-center text-xl">
                  ご注文ありがとうございました
                </p>
              )}
              {orderData && (
                <h2 className="my-3 text-center text-3xl font-bold text-black">
                  {`￥${orderData?.totalPrice}`}
                </h2>
              )}
              <div className="my-7">
                {orderList?.map((e, i) => {
                  return (
                    <div
                      className="flex h-full items-center justify-center gap-4 font-bold text-black opacity-65"
                      key={i}
                    >
                      <div className="w-32 text-left">{e.title}</div>
                      <div className="w-8 text-left">×{e.count}</div>
                      <div className="w-16 text-left">{`￥${
                        e.count * e.price
                      }`}</div>
                    </div>
                  );
                })}
                {orderData?.isStatus === "not_payed" && (
                  <div className="mt-3 text-center">
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate(
                          `/check/${
                            orderData.payment === "paypay"
                              ? orderData.id
                              : orderData.checkoutId
                          }/${orderData.payment}`
                        );
                      }}
                    >
                      決済情報の再取得
                    </Button>
                  </div>
                )}
                {params.status === "failed" && (
                  <div className="my-7">
                    <h2 className="mx-auto w-5/6 text-center text-sm ">
                      決済情報が見つかりませんでした。支払いが完了したにも関わらずこの画面が表示される場合は以下に表示されたIDをお問い合わせフォームに記載してください。
                    </h2>
                    <div className="mx-auto w-5/6 text-center text-sm text-indicatorRed">
                      ※未払いの場合はこのままで問題ありません
                    </div>
                    <div className="bg-black/80">
                      <p className="mx-auto my-8 w-5/6 break-words text-start text-xl text-white">
                        {params.id}
                      </p>
                    </div>
                    <div className="m-2 flex justify-center">
                      {isButtonLoading ? (
                        <Orbit size={40} speed={1.2} color="#006C9B" />
                      ) : (
                        <Button
                          variant="contained"
                          className="bg-red-500"
                          onClick={async () => {
                            if (params.id) {
                              try {
                                setIsButtonLoading(true);
                                await DeleteOrderDocument(params.id);
                                setIsButtonLoading(false);
                                navigate("/history");
                              } catch (e) {
                                RedirectToErrorPage(e);
                              }
                            }
                          }}
                        >
                          この注文履歴を削除する
                        </Button>
                      )}
                    </div>
                    <div className="m-2 flex justify-center">
                      <Button
                        variant="contained"
                        href={`https://docs.google.com/forms/d/e/1FAIpQLSfRRIK0WBAoMt_WN3RAKbP598LZOQAhsOrIQu8O7eAZE81x1Q/viewform?usp=pp_url&entry.951689160=${params.id}`}
                      >
                        お問い合わせフォームへ
                      </Button>
                    </div>
                  </div>
                )}
                {(orderData?.isStatus === "ordered" ||
                  orderData?.isStatus === "cooked") && (
                  <>
                    <Divider className="mt-10" />
                    <h2 className="my-8 text-center font-sans text-3xl text-black">
                      食券受け取り方法
                    </h2>
                    <Slide />
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="mx-2 text-center">
                <h1 className="text-4xl">
                  404 Not Found.
                  <br />
                </h1>
                <p>
                  <span className="text-xl">
                    {auth.currentUser?.displayName}
                    さんの
                    <br />
                    注文が見つかりませんでした。
                  </span>
                  <br />
                  決済が完了しているにも関わらずこの画面が表示される場合は、
                  以下の問い合わせボタンからお問い合わせください。
                </p>
                <div className="bg-black/80">
                  <p className="mx-auto my-8 w-5/6 break-words text-start text-xl text-white">
                    {params.id}
                  </p>
                </div>
                <div className="m-2 flex justify-center">
                  <Button
                    variant="contained"
                    href={`https://docs.google.com/forms/d/e/1FAIpQLSfRRIK0WBAoMt_WN3RAKbP598LZOQAhsOrIQu8O7eAZE81x1Q/viewform?usp=pp_url&entry.951689160=${params.id}`}
                  >
                    お問い合わせフォームへ
                  </Button>
                </div>
              </div>
            </>
          )}
          <div className="m-2 flex justify-center">
            <Link to="/history">
              <Button variant="text">履歴に戻る</Button>
            </Link>
          </div>
        </div>
      ) : (
        <LoadingAnimation type="jelly" />
      )}
    </div>
  );
};
