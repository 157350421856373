import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../config/Firebase";
import { MenuData } from "../../../types";
import { generateErrorFirebaseAndAxiosErrors } from "../../../api/helper";

export const GetMenuData = async () => {
  try {
    const querySnapshot = await getDocs(
      query(collection(db, "menu"), where("isStatus", "==", true))
    );
    const data: MenuData[] = querySnapshot.docs.map(
      (doc) => doc.data() as MenuData
    );
    if (data.length === 0) {
      throw "メニューデータが存在しません。";
    }
    return data;
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors(
      "メニューデータの取得に失敗しました。",
      e
    );
  }
};
