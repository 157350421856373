import {
  GoogleAuthProvider,
  User,
  onAuthStateChanged,
  signInWithPopup,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../../../config/Firebase";
import { SendMessageDiscord } from "../api";
import { LoginState } from "../types";

const provider = new GoogleAuthProvider();

export const useAuth = () => {
  const [user, setUser] = useState<User>();
  const [loginState, setIsLogin] = useState<LoginState>("fetching");

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setIsLogin("login");
      } else {
        setUser(undefined);
        setIsLogin("logout");
      }
    });
  }, []);

  const login = async () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        setUser(user);
        setIsLogin("login");
        await SendMessageDiscord(user.email);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const logout = () => {
    auth.signOut();
    setUser(undefined);
    setIsLogin("logout");
  };

  const deleteAccount = () => {
    if (user) {
      user.delete();
      setUser(undefined);
      setIsLogin("logout");
    }
  };

  return { user, loginState, login, logout, deleteAccount };
};
