import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db, functions } from "../../../config/Firebase";
import { OrderData } from "../../../types";
import { generateErrorFirebaseAndAxiosErrors } from "../../../api/helper";
import { httpsCallable } from "firebase/functions";
import { SetOrderIdQuantity } from "../utils/SetOrderIdQuantity";
import { AssignOrderNumber } from "./AssignOrderNumber";

export const UpdateOrderAndReduceQuantity = async (orderId: string) => {
  const washingtonRef = doc(db, "order", orderId);
  // orderDataを取得してfunctionでmenuのquantityを減らす
  try {
    const docSnap = await getDoc(washingtonRef);
    const orderData = docSnap.data() as OrderData;
    const setOrderIdQuantity = SetOrderIdQuantity(orderData.menu);
    const reduceQuantity = httpsCallable(functions, "ReduceQuantity");
    if (orderData.isStatus !== "not_payed") return;
    await Promise.all([
      reduceQuantity(setOrderIdQuantity),
      updateDoc(washingtonRef, {
        isStatus: "ordered",
      }),
      AssignOrderNumber(orderId),
    ]);
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors(
      "オーダーの更新に失敗しました。",
      e
    );
  }
};
