import { MenuData } from "../../../types";

export const SetOrderIdQuantity = (orderData: MenuData[]) => {
  const data = orderData.reduce((acc, cur) => {
    const isExist = acc.find((e) => e.id === cur.id);
    if (isExist) {
      isExist.quantity++;
    } else {
      acc.push({ id: cur.id, quantity: 1 });
    }
    return acc;
  }, [] as { id: string; quantity: number }[]);
  return data;
};
