import { Timestamp, doc, setDoc } from "firebase/firestore";
import {
  RandomID,
  generateErrorFirebaseAndAxiosErrors,
} from "../../../api/helper";
import { MenuData, OrderData, UserData, paymentType } from "../../../types";
import { db } from "../../../config/Firebase";

interface OrderSubmitProps {
  user: UserData;
  totalPrice: number;
  menu: MenuData[];
  payment: paymentType;
}

export const OrderSubmit = async (props: OrderSubmitProps) => {
  const id = RandomID();
  const timeStamp = Timestamp.now();
  const orderData: OrderData = {
    id: id,
    user: props.user,
    totalPrice: props.totalPrice,
    menu: props.menu,
    date: timeStamp,
    isStatus: "not_payed",
    payment: props.payment,
  };
  try {
    await setDoc(doc(db, "order", id), orderData);
    return orderData;
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors(
      "注文情報の送信に失敗しました。",
      e
    );
  }
};
