import { httpsCallable } from "firebase/functions";
import {
  IsGetSystemStatus,
  generateErrorFirebaseAndAxiosErrors,
} from "../../../api/helper";
import { MenuData } from "../../../types";
import { functions } from "../../../config/Firebase";
import { SetOrderIdQuantity } from "../utils";

export const CantOrderTitle = async (orderData: MenuData[]) => {
  const data = SetOrderIdQuantity(orderData);
  const cantOrderTitle = httpsCallable(functions, "cantOrderTitle");
  try {
    const isStatus = await IsGetSystemStatus();
    if (isStatus) {
      const title = await cantOrderTitle(data);
      return title.data;
    } else {
      throw "システムメンテナンス中です。";
    }
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors("在庫の確認に失敗しました。", e);
  }
};
