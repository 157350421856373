import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import {
  Yesterday,
  generateErrorFirebaseAndAxiosErrors,
} from "../../../api/helper";
import { OrderData } from "../../../types";
import { db } from "../../../config/Firebase";

export const TodayUserOrderGet = async (
  userId: string,
  searchWord: string[]
) => {
  try {
    const q = query(
      collection(db, "order"),
      orderBy("date", "desc"),
      where("date", ">", Yesterday()),
      where("user.uid", "==", userId),
      where("isStatus", "in", searchWord),
      limit(1)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data() as OrderData);
  } catch (e) {
    throw generateErrorFirebaseAndAxiosErrors(
      "注文情報の取得に失敗しました。",
      e
    );
  }
};
